@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
}
// Link non evidenziati al click
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.2s ease-in-out;
}

ul {
  text-decoration: none;
  list-style: none;
}

h1 {
  margin: 0;
  text-align: center;
  font-size: 4rem;
}

.hidden {
  display: none;
}

.main__text {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.5;
}

.overlay {
  position: absolute;
  opacity: 0.3;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -1;
}

.img__container {
  position: relative;
}

.btn__dark {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: black;
  color: $light-color;
  font-size: 1.1rem;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  &:hover {
    background-color: $hover-color;
    cursor: pointer;
    .arrow__icon {
      transition: all 0.3s ease-in-out;
      // padding-left: 5px;
      // padding-right: 5px;
    }
  }
  .arrow__icon {
    padding-bottom: 2px;
  }
}

.items__container {
  text-align: left;
}

.jarallax {
  height: 80vh;
  position: relative;
  z-index: 0;
}

.content__title {
  font-size: 2.2rem;
  margin-bottom: 5vh;
  color: $medium-color;
}

.flex__reverse {
  display: flex;
  flex-direction: column-reverse;
}

.current {
  color: $medium-color;
}
